import { type FC, type PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import reasons01 from 'assets/images/detail/reasons_01.jpg';
import reportSample from 'assets/images/detail/report-sample.png';
import IconImprovement from 'assets/images/top/icon_improvement.svg';
import IconNewspaper from 'assets/images/top/icon_newspaper.svg';
import IconRoute from 'assets/images/top/icon_route.svg';
import keyVisual from 'assets/images/top/kv.png';
import solution01 from 'assets/images/top/solutions_01.jpg';
import solution02 from 'assets/images/top/solutions_02.jpg';
import solution03 from 'assets/images/top/solutions_03.jpg';
import { usePageTracking } from 'utils/ga';
import PublicLayout from 'components/templates/PublicLayout';

const Home: FC<PropsWithChildren> = () => {
  usePageTracking(); // ページビュー計測

  return (
    <PublicLayout>
      <section className="bg-white dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 pt-20 pb-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12 lg:pt-28">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-gs-brand text-4xl font-extrabold leading-none tracking-tight md:text-5xl xl:text-5xl dark:text-white">
              Google Analyticsで
              <br />
              見える、分かる、成長する
            </h1>
            <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              成果が上がるWeb解析ツールでビジネスをサポート！まずは無料でお試しください！
            </p>
            <div className="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
              <Link
                className="inline-flex items-center justify-center px-5 py-3 text-sm font-medium text-center text-gray-900 border border-gray-200 rounded-lg sm:w-40 hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
                to="/users/sign_up"
              >
                無料で試す
              </Link>
            </div>
          </div>
          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img alt="見える、分かる、成長する" src={keyVisual} />
          </div>
        </div>
      </section>

      <section className="bg-gray-50 dark:bg-gray-800">
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
          <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gs-brand dark:text-white">
              Webマーケティング現場の3つの問題点
            </h2>
          </div>
          <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
            <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <div className="flex items-baseline justify-center my-8">
                <div className="top-problems__image">
                  <img alt="" src={IconNewspaper} />
                </div>
              </div>
              <h3 className="mb-4 text-2xl font-semibold text-gray-700">
                何を見ていいか分からない
              </h3>
              <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                ページビューやユーザー数、直帰率など、指標がありすぎてどれを見ればいいのか分からない
              </p>
            </div>
            <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <div className="flex items-baseline justify-center my-8">
                <div className="top-problems__image">
                  <img alt="" src={IconImprovement} />
                </div>
              </div>
              <h3 className="mb-4 text-2xl font-semibold text-gray-700">
                改善点が分からない
              </h3>
              <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                指標は毎月見ているが、どういう視点で見れば成果が上がるのか分からない
              </p>
            </div>
            <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <div className="flex items-baseline justify-center my-8">
                <div className="top-problems__image">
                  <img alt="" src={IconRoute} />
                </div>
              </div>
              <h3 className="mb-4 text-2xl font-semibold text-gray-700">
                目標への過程が分からない
              </h3>
              <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                キーイベントは設定しているが、毎月目標を達成しそうなのか分からない
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-gray-900">
        <div className="max-w-screen-lg px-8 py-8 mx-auto space-x-12 space-y-12 lg:space-y-20 lg:py-24">
          <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gs-brand dark:text-white">
              Growth Support を使うと
            </h2>
          </div>
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-2xl font-extrabold tracking-tight text-gray-700 dark:text-white">
                サイトの状態が分かる
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Googleアナリティクスの重要指標をグラフ化することで、継続的なモニタリングをラクに行うことができ、サイトの良し悪しやトレンドの把握など、サイトの変化に気づけるようになります。
              </p>
            </div>
            <img alt="サイトの指標をグラフ化" src={solution01} />
          </div>
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <img alt="PDCAによる改善イメージ" src={solution02} />
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-2xl font-extrabold tracking-tight text-gray-700 dark:text-white">
                改善ポイントが分かる
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                サイトを分析し、重要度の高い改善点をご提案します。また、施策や結果をまとめるテンプレートがあるので、結果が分かりやすく次回施策に活かすことができます。
              </p>
            </div>
          </div>
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-2xl font-extrabold tracking-tight text-gray-700 dark:text-white">
                目標への達成状況がわかる
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                キーイベントをモニタリングすることで、月の達成状況を確認できます。前月・前年同月の値と比較することで月末に達成できるのかの経過を確認することができます。
              </p>
            </div>
            <img alt="複雑な作業なく簡単導入できるイメージ" src={solution03} />
          </div>
        </div>
      </section>

      <section className="bg-gray-50 dark:bg-gray-800">
        <div className="max-w-screen-xl px-4 py-8 mx-auto space-y-12 lg:space-y-20 lg:py-24 lg:px-6">
          <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gs-brand dark:text-white">
              Growth Support が選ばれる理由
            </h2>
          </div>
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-700 dark:text-white">
                アクションを起こせるモニタリングレポート
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                Growth Support
                では重要指標を今月と前月・前年同月を比較することができ、今のサイト状況が分かります。
                <br />
                月途中で状況を確認できるので、月末に向けて達成可否を予測でき、アクションを起こすことができます。
              </p>
            </div>
            <img
              className="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex"
              alt="アクションを起こせるグラフの画面"
              src={reasons01}
            />
          </div>
          <div className="items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
            <div className="text-gray-500 sm:text-lg dark:text-gray-400">
              <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-700 dark:text-white">
                充実したエクセルレポート
              </h2>
              <p className="mb-8 font-light lg:text-xl">
                ページックプランでは、Google
                アナリティクスのデータからエクセルレポートを作成することが可能です。
                <br />
                詳細なデータも載せていますので、データの共有や施策立案のサポートにご活用ください。
              </p>
            </div>
            <img
              className="hidden w-full mb-4 rounded-lg lg:mb-0 lg:flex"
              alt="エクセルレポートサンプル"
              src={reportSample}
            />
          </div>
          <div className="flex items-center justify-center mt-4">
            <Link
              className="inline-flex items-center justify-center w-full px-5 py-3 text-sm text-xl font-medium text-center text-gray-900 border border-gray-200 rounded-lg sm:w-60 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              to="/users/sign_up"
            >
              {'無料で試す >'}
            </Link>
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-gray-900">
        <div className="max-w-screen-xl px-6 py-8 mx-auto lg:py-24 lg:px-6">
          <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gs-brand dark:text-white">
              料金一覧
            </h2>
            <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
              まずはフリープランでお試しください。
            </p>
          </div>
          <div className="space-y-8 lg:grid lg:grid-cols-2 sm:gap-6 xl:gap-10 lg:space-y-0">
            <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-700 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <h3 className="mb-4 text-2xl font-semibold">フリープラン</h3>
              <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                Google Analytics
                のデータを使って、PV数やセッション数などの基本指標をモニタリングできます。
              </p>
              <div className="flex items-baseline justify-center my-8">
                <span className="mr-2 text-5xl">0</span>
                <span className="text-gray-500 dark:text-gray-400">/month</span>
              </div>
              <ul className="mb-8 space-y-4 text-left">
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span>モニタリングレポート(Web)</span>
                </li>
              </ul>
            </div>
            <div className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-700 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
              <h3 className="mb-4 text-2xl font-semibold">ベーシックプラン</h3>
              <p className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
                フリープランに加えて、ExcelレポートのDLが可能です。また今後開発する新機能をご利用いただけます。
              </p>
              <div className="flex items-baseline justify-center my-8">
                <span className="mr-2 text-5xl">
                  5,000<span className="mr-2 text-lg font-extrabold">円</span>
                </span>
                <span className="text-gray-500 dark:text-gray-400">/month</span>
              </div>
              <ul className="mb-8 space-y-4 text-left">
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span>モニタリングレポート(Web)</span>
                </li>
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span>エクセルレポート</span>
                </li>
                <li className="flex items-center space-x-3">
                  <svg
                    className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span>その他、新機能</span>
                </li>
                <li className="flex items-center space-x-3">
                  <p className="mb-5 font-light text-gray-500 dark:text-gray-400">
                    ※ Growth Support
                    では、今後さらに機能追加を行い、役立つツール群を用意する予定です。
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex items-center justify-center mt-16">
            <Link
              className="inline-flex items-center justify-center w-full px-5 py-3 text-sm text-xl font-medium text-center text-gray-900 border border-gray-200 rounded-lg sm:w-60 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
              to="/users/sign_up"
            >
              {'無料で試す >'}
            </Link>
          </div>
        </div>
      </section>
      <section className="bg-gray-50 dark:bg-gray-800">
        <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-16 lg:px-6">
          <div className="max-w-screen-sm mx-auto text-center">
            <h2 className="mb-4 text-3xl font-extrabold leading-tight tracking-tight text-gs-brand dark:text-white">
              お問い合わせ
            </h2>
            <p className="mb-6 font-light text-gray-500 dark:text-gray-400 md:text-lg">
              サービスに関するお問い合わせは以下のメールアドレス宛にご連絡ください。
              <br />
              ※ ＠が全角になっているので半角に直してください。
              <br />
              info＠growth-support.net 3営業日以内にご連絡致します。
            </p>
          </div>
        </div>
      </section>
      <section className="bg-white dark:bg-gray-900">
        <div className="max-w-screen-lg px-8 py-8 mx-auto space-x-12 space-y-12 lg:space-y-20 lg:py-24">
          <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gs-brand dark:text-white">
              最新情報
            </h2>
          </div>
          <div className="items-center snap-y">
            <dl className="news__list">
              <dt className="news__date">2020/01/02</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2020/01/02/000023"
                  rel="noreferrer"
                >
                  有料プランでできることとBasic プランへの変更方法
                </a>
              </dd>
              <dt className="news__date">2020/01/01</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2020/01/01/134454"
                  rel="noreferrer"
                >
                  有料化しました！
                </a>
              </dd>
              <dt className="news__date">2019/11/20</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2019/11/20/111222"
                  rel="noreferrer"
                >
                  登録したビューを削除する機能を追加しました
                </a>
              </dd>
              <dt className="news__date">2019/11/10</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/new-analytics-191110"
                  rel="noreferrer"
                >
                  アナリティクス一覧ページの表示を登録済みとすべてに分けました！
                </a>
              </dd>
              <dt className="news__date">2019/04/11</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2019/04/11/075514"
                  rel="noreferrer"
                >
                  年次レポートにデバイスデータを追加しました！
                </a>
              </dd>
              <dt className="news__date">2019/04/07</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/landing-page-in-annual"
                  rel="noreferrer"
                >
                  年次レポートにLPデータを追加しました！
                </a>
              </dd>
              <dt className="news__date">2019/04/06</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/add-summary-and-channel-group-in-annual-page"
                  rel="noreferrer"
                >
                  年次レポートにサマリーと流入元別データを追加しました！
                </a>
              </dd>
              <dt className="news__date">2019/03/17</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2019/03/17/114500"
                  rel="noreferrer"
                >
                  年次レポートにおいて、開始月を設定できるようにしました！
                </a>
              </dd>
              <dt className="news__date">2018/08/26</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2018/08/26/145118"
                  rel="noreferrer"
                >
                  2ページ比較できるようにしました！
                </a>
              </dd>
              <dt className="news__date">2018/08/25</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2018/08/25/165743"
                  rel="noreferrer"
                >
                  1ページを2つの期間で比較できるようにしました！
                </a>
              </dd>
              <dt className="news__date">2018/08/20</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2018/08/20/151928"
                  rel="noreferrer"
                >
                  比較レポートを登録できるようにしました！
                </a>
              </dd>
              <dt className="news__date">2018/08/13</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2018/08/13/200057"
                  rel="noreferrer"
                >
                  比較レポートに新規・リピートユーザーデータを追加しました！
                </a>
              </dd>
              <dt className="news__date">2018/08/07</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2018/08/07/122951"
                  rel="noreferrer"
                >
                  新規・リピートユーザーデータを月次レポートに反映させました！
                </a>
              </dd>
              <dt className="news__date">2018/08/06</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2018/08/06/182924"
                  rel="noreferrer"
                >
                  新規・リピートユーザーのデータを表示するようにしました！
                </a>
              </dd>
              <dt className="news__date">2018/08/06</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2018/08/06/110722"
                  rel="noreferrer"
                >
                  エクセルレポートにデバイス別データを追加しました！
                </a>
              </dd>
              <dt className="news__date">2018/08/04</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2018/08/04/224238"
                  rel="noreferrer"
                >
                  デバイス別でのデータを表示するようにしました
                </a>
              </dd>
              <dt className="news__date">2018/07/27</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2018/07/27/142425"
                  rel="noreferrer"
                >
                  月次レポートをパワーアップしました！
                </a>
              </dd>
              <dt className="news__date">2018/07/03</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2018/07/03/161626"
                  rel="noreferrer"
                >
                  デバイス別にコンバージョンを見れるようにしました！
                </a>
              </dd>
              <dt className="news__date">2018/06/29</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2018/06/29/160109"
                  rel="noreferrer"
                >
                  期間を自由に指定して比較できるレポートを追加しました
                </a>
              </dd>
              <dt className="news__date">2018/06/29</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2018/06/29/154002"
                  rel="noreferrer"
                >
                  性年代に過去のデータを表示するようにしました！
                </a>
              </dd>
              <dt className="news__date">2018/06/24</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2018/06/24/120137"
                  rel="noreferrer"
                >
                  時間帯別、曜日別ユーザー数を調整しました
                </a>
              </dd>
              <dt className="news__date">2018/03/14</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2018/03/14/154420"
                  rel="noreferrer"
                >
                  Google Analytics のデータをSlackに通知する機能を追加しました
                </a>
              </dd>
              <dt className="news__date">2018/01/14</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2018/01/14/174118"
                  rel="noreferrer"
                >
                  サイドバーをレポートと分析にし、通知を充実させました！
                </a>
              </dd>
              <dt className="news__date">2018/01/14</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2018/01/14/174005"
                  rel="noreferrer"
                >
                  毎月の状況をメールでお知らせするようにしました！
                </a>
              </dd>
              <dt className="news__date">2018/01/14</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2018/01/14/173527"
                  rel="noreferrer"
                >
                  訪問企業を通知するようにしました！
                </a>
              </dd>
              <dt className="news__date">2017/10/02</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/10/02/154810"
                  rel="noreferrer"
                >
                  ランディングページ分析で改善ページを提案するようにしました
                </a>
              </dd>
              <dt className="news__date">2017/09/25</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/09/25/215036"
                  rel="noreferrer"
                >
                  月次レポートをエクセルでダウンロードできるようにしました！
                </a>
              </dd>
              <dt className="news__date">2017/09/21</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/09/21/000000"
                  rel="noreferrer"
                >
                  ランディングページ分析のデータをダウンロードできるようにしました！
                </a>
              </dd>
              <dt className="news__date">2017/09/20</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/09/20/000000"
                  rel="noreferrer"
                >
                  参照元 x
                  ランディングページ分析のデータをダウンロードできるようにしました！
                </a>
              </dd>
              <dt className="news__date">2017/09/19</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/09/19/000000"
                  rel="noreferrer"
                >
                  検索アナリティクスのデータをダウンロードできるようにしました！
                </a>
              </dd>
              <dt className="news__date">2017/09/14</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/09/14/000000"
                  rel="noreferrer"
                >
                  ランディングページの検索クエリを表示するようにしました！
                </a>
              </dd>
              <dt className="news__date">2017/09/12</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/09/12/000000"
                  rel="noreferrer"
                >
                  参照元 x ランディングページ分析レポートを追加しました！
                </a>
              </dd>
              <dt className="news__date">2017/09/10</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/09/10/000000"
                  rel="noreferrer"
                >
                  参照元ランキングレポートを追加しました！
                </a>
              </dd>
              <dt className="news__date">2017/09/09</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/09/09/000000"
                  rel="noreferrer"
                >
                  曜日別レポートを追加しました！
                </a>
              </dd>
              <dt className="news__date">2017/09/07</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/09/07/000000"
                  rel="noreferrer"
                >
                  性別x年代レポートを追加しました！
                </a>
              </dd>
              <dt className="news__date">2017/09/05</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/09/05/000000"
                  rel="noreferrer"
                >
                  ランディングページ分析機能を追加しました！
                </a>
              </dd>
              <dt className="news__date">2017/09/03</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/09/03/000000"
                  rel="noreferrer"
                >
                  年次レポートの前年同月比との差を分かりやすくしました！
                </a>
              </dd>
              <dt className="news__date">2017/09/02</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/09/02/000000"
                  rel="noreferrer"
                >
                  月次レポートの時間帯別ユーザー数の差を分かりやすくしました！
                </a>
              </dd>
              <dt className="news__date">2017/09/01</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/09/01/000000"
                  rel="noreferrer"
                >
                  人気閲覧ページ、ランディングページの重要ページが分かりやすくなりました！
                </a>
              </dd>
              <dt className="news__date">2017/08/29</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/08/29/000000"
                  rel="noreferrer"
                >
                  検索アナリティクスが見れるようになりました！
                </a>
              </dd>
              <dt className="news__date">2017/08/27</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/08/27/000000"
                  rel="noreferrer"
                >
                  年次レポートが見れるようになりました！
                </a>
              </dd>
              <dt className="news__date">2017/08/26</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/08/26/000000"
                  rel="noreferrer"
                >
                  月次レポートに人気閲覧ページを追加しました！
                </a>
              </dd>
              <dt className="news__date">2017/08/07</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/08/07/000000"
                  rel="noreferrer"
                >
                  月次レポートが見れるようになりました！
                </a>
              </dd>
              <dt className="news__date">2017/08/02</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/08/02/000000"
                  rel="noreferrer"
                >
                  サービス理念ページを作成しました！
                </a>
              </dd>
              <dt className="news__date">2017/08/01</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/08/01/000000"
                  rel="noreferrer"
                >
                  サービスロゴを作成しました！
                </a>
              </dd>
              <dt className="news__date">2017/07/02</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growth-support.hatenablog.com/entry/2017/07/02/000000"
                  rel="noreferrer"
                >
                  利用規約、プライバシーポリシーを更新しました！
                </a>
              </dd>
              <dt className="news__date">2017/06/25</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growthsupport.tumblr.com/post/165296463389"
                  rel="noreferrer"
                >
                  コンバージョン登録ができるようになりました！
                </a>
              </dd>
              <dt className="news__date">2017/03/01</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growthsupport.tumblr.com/post/165283551164"
                  rel="noreferrer"
                >
                  Growth Support をリリースしました！
                </a>
              </dd>
              <dt className="news__date">2017/03/01</dt>
              <dd className="p-news__title">
                <a
                  className="truncate"
                  target="_blank"
                  href="https://growthsupport.tumblr.com/post/165284588764"
                  rel="noreferrer"
                >
                  サイトアクセスのモニタリングができるようになりました！
                </a>
              </dd>
            </dl>
          </div>
        </div>
      </section>
    </PublicLayout>
  );
};

export default Home;
